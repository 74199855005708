import lights from './lighters.json'
import images from './images';

let gifs = images.filter((image)=>{
    if(image.format=="gif") return true
})
var lighters = [];

for(let l of lights){
    for(let g of gifs){
        if(l.design==g.design){
            l.img=g.img;
            lighters.push(l)
        }
    }
}


export default lighters
