import { Typography } from '@mui/material'
import {useEffect} from 'react'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import images from './images'

import styles from './styles.module.css'
import back_img from './back.png'

function ImageGrid({images,size,func}) {
  
  let dims = {
    width:"60vw",
    height:"35vw",
    cols:3,
    gtr:"15vh 35vw",
    rh:300
  }
  if(window.innerHeight > window.innerWidth){
    dims.width="80vw"
    dims.height="45vh"
    dims.cols = 1;
    dims.gtr="15vh 45vh";
    dims.rh=350;
  }

  return (
    <div style={{display:"grid",gridTemplateRows:dims.gtr,gridTemplateColumns:"1fr",placeItems:"center"}}>
      <Typography variant="h3" component="h1"  sx={{fontFamily:"Montserrat",textShadow:"2px 2px black"}} >Gallery</Typography>
      <ImageList sx={{ width: dims.width, height:dims.height,opacity:"1",overflowY:"auto",gridGap:"15px !important"}} cols={dims.cols} rowHeight={dims.rh} className={styles.product_gallery}>
        {images.map((item) => (
          <ImageListItem  key={item.img}>
            <img
              src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={item.design}
              design = {item.design}
              loading="lazy"
              
              onClick={func}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}


const Fragility = ({product}) =>{
  let frag = "";
  let col="white";
  let warning = "";
  switch (product.fragility){
    case 1:
      frag="Very Low";
      col="green";
      warning="scratches are your only concern"
      break;
    case 2:
      frag="Low";
      col="limegreen";
      warning="small pieces may break off";
      break;
    case 3:
      frag="Medium";
      col="yellow";
      warning="likely to break on falling"
      break;
    case 4:
      frag="High";
      col="orange";
      warning="made of many small pieces, likely to shatter on impact"
      break;
  }

  return (
    <div className={styles.fragility}>
      <p style={{fontFamily:"Montserrat",fontSize:"1.5rem",alignSelf:"flex-end"}}>fragility</p>
      <p className={styles.frag} style={{color:col}}>{frag}</p>
      <p className={styles.warn} style={{color:col}}>{warning}</p>
    </div>
  )
}

const Product = ({state,product,func}) => {

  let design = images.filter((image)=>{
    if(image.design==product.design && image.format!="gif") return true
  })

  useEffect(() => {
    design = images.filter((image)=>{
      if(image.design==product.design && image.type!="featured") return true
    })
  }, [product]);


  return (
    <div className={styles.product}>
        <img src={back_img} alt="back" className={styles.back} onClick={func}/>
        <img src={product.img} alt={product.title} className={styles.product_featured}/>
        <img src={product.img} alt={product.title} className={styles.product_mirror}/>
        <Typography variant="h3" component="h1"  sx={{letterSpacing:"15px",fontFamily:"Aloja"}} className={styles.product_title}>{product.title}</Typography>
        <Typography variant="p" className={styles.subtitle}>{product.desc}</Typography>
        <Typography variant="p" className={styles.buy} onClick={func}>buy now</Typography>
        
        <div className={styles.details}>
          <Typography variant="h3" className={styles.dimensions}>Product Details</Typography>
          <Fragility product={product}/>
          
          <ImageGrid images={design} />
        </div>
        
    </div>
  )
}

export default Product