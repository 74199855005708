import * as React from 'react';
import images from './images';
import lighters from './lighters.js'
import styles from './styles.module.css'

function Lighter({img,title,desc,lighter,func}) {

    return (
        <section onClick={()=>{
          func(lighter.design)
        }}>
            <img src={img} alt={title} />
            <div>
                <h2 className={styles.lighter_title}>
                    {title}
                </h2>
                <p style={{color:"white"}}>{desc}</p>
            </div>
            
            
        </section>
    )
}

function Shop({state,func}) {
  return (
    <div className={styles.shop}>
      {lighters.map((item,key)=>(
        <Lighter img={item.img} lighter={item} title={item.title} desc={item.desc} key={key} state={state} func={func} />
      ))}
    </div>
  );
}

export default Shop