//? React and MUI Libraries
import { Typography } from '@mui/material';
import { useState,useEffect } from 'react';
import InstagramIcon from './instagram-icon.png';
import WhatsAppIcon from './whatsapp.png';


//? Stylesheets
import './App.css' //? querySelector(.syntax)
import styles from './styles.module.css' //? {styles.syntax}

import Gallery from './Gallery'; //TODO fix dependency

//? Components
import Menu from './Menu'; 
import Footer from './Footer';
import Shop from './Shop';
import Product from './Product';
import Home from './Home';

//? Image and text data for products
import images from './images';
import lighters from './lighters.js'

let featured = images.filter(image=> image.type=="featured") //* array of featured gifs for home

let shops = images.filter(image => image.format=="gif") //* array of gif thumbnails for shop

//TODO fix dependency
let cols = [4,3]
let rows = 200
let widths = ["80vw","60vw"]
let heights = ['60vh','420vh']
if(window.innerHeight > window.innerWidth){
  rows = 150;
  cols = [2,3]
  widths = ["100vw","100vw"]
  heights = ['80vh','420vh']
}

//! Primary Component
function App() {
  
  const [menu,setMenu] = useState([{title:"home",selected:"yellow"},{title:"shop",selected:"white"},{title:"gallery",selected:"white"},{title:"contact",selected:"white"}]) 

  const [product, setProduct] = useState({
    title:"default",
    design:"plain",
    desc:"default"
  });


  //TODO centralize interactions
  function hideall(){
    window.scrollY=0;
    document.getElementById("home").style.display="none";
    document.getElementById("shop").style.display="none";
    document.getElementById("gallery").style.display="none";
    document.getElementById("contact").style.display="none";
    document.getElementById("product").style.display="none";
    for(let m of menu){
      m.selected="white";
    }
    let dom = document.querySelectorAll('.'+styles.menu_items)
    for(let d of dom){
      d.style.color="white";
    }
  }
  function change(e){
    e.preventDefault();
    hideall();

    switch(e.target.innerHTML){
      case "home":
        document.getElementById("home").style.display="grid";
        document.querySelectorAll("."+styles.menu_items)[0].style.color="yellow";
        document.querySelector(".App").style.overflowY="hidden";
        menu[0].selected="yellow";
        break;
      case "shop":
        document.getElementById("shop").style.display="grid";
        document.querySelector(".App").style.overflowY="auto";
        menu[1].selected="yellow";
        document.querySelectorAll("."+styles.menu_items)[1].style.color="yellow";
        break;
      case "gallery":
        document.getElementById("gallery").style.display="grid";
        document.querySelector(".App").style.overflowY="auto";
        menu[2].selected="yellow";
        document.querySelectorAll("."+styles.menu_items)[2].style.color="yellow";
        break;
      case "contact":
        document.getElementById("contact").style.display="grid";
        document.querySelector(".App").style.overflowY="hidden";
        menu[3].selected="yellow";
        document.querySelectorAll("."+styles.menu_items)[3].style.color="yellow";
        break;
      case "buy now":
        document.getElementById("contact").style.display="grid";
        document.querySelector(".App").style.overflowY="hidden";
        menu[3].selected="yellow";
        document.querySelectorAll("."+styles.menu_items)[3].style.color="yellow";
        break;
      case "":
        document.getElementById("shop").style.display="grid";
        document.querySelector(".App").style.overflowY="auto";
        menu[1].selected="yellow";
        document.querySelectorAll("."+styles.menu_items)[1].style.color="yellow";
        break;
    }
  }
  function contact(e){
    e.preventDefault();
    
    switch(e.target.id){
      case "instagram":
        window.location="https://www.instagram.com/thelighter.club"
        break;
      case "whatsapp":
        window.location="https://wa.me/+918007501833"
        break;
    }
  }
  function home(e){
    e.preventDefault();
    hideall();
    window.scrollY=0;
    document.getElementById('product').style.display="grid";
    for(let l of lighters){
      if(l.design==e.target.alt){
        setProduct(l);
      }
    }
  }
  function shop(lighter){
    console.log(lighter)
    hideall();
    window.scrollY=0;
    document.getElementById('product').style.display="grid";
    for(let l of lighters){
      if(l.design==lighter){
        setProduct(l);
      }
    }
  }

  //! Handles interaction
  useEffect(() => {
    setMenu(menu)
  }, [menu]);

  return (
    //! Mark-Up in JSX
    <div className="App">

      <Menu func={change} menu={menu} />

      <Footer /> 

      <section id="home">
        <Typography variant="h1" component="h1" className={styles.title}> 
          featured
        </Typography>
        <Home images={featured} func={home}/>
      </section>

      <section id="shop">
        <Typography variant="h1" component="h1" className={styles.title}>
          shop
        </Typography>
        <Shop func={shop} state={setProduct} /> 
      </section>

      <section id="gallery">
        <Typography variant="h1" component="h1" className={styles.title}>
          gallery
        </Typography>
        <Gallery images={images} size={{width:"60vw",height:"420vh",cols:cols[1],row:200}} 
        cssclass="gallery_image"/>
      </section>

      <section id="contact"> 
        <Typography variant="h1" component="h1" className={styles.title}>
          contact
        </Typography>
        <div className={styles.contact}>
          <img src={`${InstagramIcon}`}
          className={styles.contact_icon} id="instagram" onClick={contact}></img>
          <img src={`${WhatsAppIcon}`}
          className={styles.contact_icon} id="whatsapp" onClick={contact}></img>
          
        </div>
      </section>
      
      <section id="product">
        <Product state={setProduct} product={product} func={change}/>
      </section>

    </div>

    
  );
}

export default App;
