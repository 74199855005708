import React from 'react'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import styles from './styles.module.css'

const Home = ({images,func})=>{

    var cols=4;
    var width = "80vw"
    var height = '60vh'
    var imageclass = styles.home_image
    if(window.innerHeight > window.innerWidth){
        cols = 4;
        width = "100vw"
        height = '60vh'
        
    }
  

  return (
    <ImageList sx={{ width: width, height: height,opacity:"1",overflowY:"auto"}} cols={cols} rowHeight={300}>
      {images.map((item) => (
        <ImageListItem className={styles.home_image_container} key={item.img}>
          <img
            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            alt={item.design}
            design = {item.design}
            loading="lazy"
            className={imageclass}
            onClick={func}
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

export default Home