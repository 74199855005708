import bricks from './lighters/brick.gif'
import night from './lighters/night.gif'
import hookah from './lighters/hookah.gif'
import weed from './lighters/weed_white.gif'
import shrooms from './lighters/shrooms.gif'
import plain from './lighters/plain.gif'
import night1 from './lighters/1.jpg'
import night2 from './lighters/2.jpg'
import night3 from './lighters/3.jpg'
import hookah1 from './lighters/4.jpg'
import hookah2 from './lighters/5.jpg'
import hookah3 from './lighters/6.jpg'
import brick1 from './lighters/7.jpg'
import brick2 from './lighters/8.jpg'
import brick3 from './lighters/9.jpg'
import lighter1 from './lighters/10.jpg'
import lighter2 from './lighters/11.jpg'
import lighter3 from './lighters/12.jpg'
import lighter4 from './lighters/13.jpg'
import lighter5 from './lighters/14.jpg'
import lighter6 from './lighters/15.jpg'
import lighter7 from './lighters/16.jpg'
import lighter8 from './lighters/17.jpg'
import lighter9 from './lighters/18.jpg'
import lighter10 from './lighters/19.jpg'
import lighter11 from './lighters/20.jpg'
import lighter12 from './lighters/21.jpg'
import lighter13 from './lighters/22.jpg'
import lighter14 from './lighters/23.jpg'
import lighter15 from './lighters/24.jpg'
import lighter16 from './lighters/25.jpg'
import lighter17 from './lighters/26.jpg'
import lighter18 from './lighters/27.jpg'


var images = [
    {
        alt:"night 1",
        img: night1,
        design:"night",
        type:"gallery"
    },
    {
        alt:"night 2",
        img: night2,
        design:"night",
        type:"gallery"
    },

    {
        alt:"night 3",
        img: night3,
        design:"night",
        type:"gallery"
    },

    {
        alt:"hookah 1",
        img: hookah1,
        design:"hookah",
        type:"gallery"
    },

    {
        alt:"hookah 2",
        img: hookah2,
        design:"hookah",
        type:"gallery"
    },

    {
        alt:"hookah 3",
        img: hookah3,
        design:"hookah",
        type:"gallery"
    },
    {
        alt:"brick 1",
        img: brick1,
        design:"brick",
        type:"gallery"
    },
    {
        alt:"brick 2",
        img: brick2,
        design:"brick",
        type:"gallery"
    },

    {
        alt:"brick 3",
        img: brick3,
        design:"brick",
        type:"gallery"
    },

    {
        alt:"lighter 1",
        img: lighter1,
        design:"shrooms",
        type:"gallery"
    },
    {
        alt:"lighter 2",
        img: lighter2,
        design:"shrooms",
        type:"gallery"
    },
    {
        alt:"lighter 3",
        img: lighter3,
        design:"shrooms",
        type:"gallery"
    },

    {
        alt:"lighter 4",
        img: lighter4,
        design:"weed",
        type:"gallery"
    },
    {
        alt:"lighter 5",
        img: lighter5,
        design:"weed",
        type:"gallery"
    },
    {
        alt:"lighter 6",
        img: lighter6,
        design:"weed",
        type:"gallery"
    },
    {
        alt:"lighter 7",
        img: lighter7,
        design:"weed",
        type:"gallery"
    },
    {
        alt:"lighter 8",
        img: lighter8,
        design:"weed",
        type:"gallery"
    },
    {
        alt:"lighter 9",
        img: lighter9,
        design:"weed",
        type:"gallery"
    },
    {
        alt:"lighter 10",
        img: lighter10,
        design:"plain",
        type:"gallery"
    },
    {
        alt:"lighter 11",
        img: lighter11,
        design:"plain",
        type:"gallery"
    },
    {
        alt:"lighter 12",
        img: lighter12,
        design:"plain",
        type:"gallery"
    },
    {
        alt:"lighter 13",
        img: lighter13,
        design:"plain",
        type:"gallery"
    },
    {
        alt:"lighter 14",
        img: lighter14,
        design:"plain",
        type:"gallery"
    },
    {
        alt:"lighter 15",
        img:lighter15,
        design:"plain",
        type:"gallery"
    },
    {
        alt:"shrooms",
        img:shrooms,
        format:"gif",
        design:"shrooms"
    },
    {
        alt:"night",
        img:night,
        type:"featured",
        format:"gif",
        design:"night"
    },{
        alt:"bricks",
        img:bricks,
        type:"featured",
        format:"gif",
        design:"brick"
    },{
        alt:"weed",
        img:weed,
        type:"featured",
        format:"gif",
        design:"weed"
    },{
        alt:"hookah",
        img:hookah,
        type:"featured",
        format:"gif",
        design:"hookah"
    },{
        alt:"plain",
        img:plain,
        format:"gif",
        design:"plain"
    },{
        alt:"lighter 16",
        img: lighter16,
        design:"group",
        type:"gallery"
    },
    {
        alt:"lighter 17",
        img: lighter17,
        design:"group",
        type:"gallery"
    },
    {
        alt:"lighter 18",
        img: lighter18,
        design:"group",
        type:"gallery"
    }

]

export default images