import styles from './styles.module.css'

const Menu = ({func,menu}) => {
  
  return (
    <div className={styles.menu}>
        {menu.map((item,key)=>(
          <p className={styles.menu_items} key={key} style={{color:item.selected}} onClick={func}>
          {item.title} 
          </p>
        ))}

    </div>
  )
}

export default Menu