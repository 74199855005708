import React from 'react'
import styles from './styles.module.css'

const Footer = () => {
  return (
    <div className={styles.footer}>
        <h2>
            available for commission
        </h2>
        
    </div>
  )
}

export default Footer