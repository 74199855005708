import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import styles from './styles.module.css'


export default function Gallery({images,size,cssclass,func}) {
  let cssc;
  if(cssclass=="home_image"){
    cssc=styles.home_image;
  }else{
    cssc=styles.gallery_image;
  }
  

  return (
    <ImageList sx={{ width: size.width, height: size.height,opacity:"1",overflow:"hidden"}} cols={size.cols} rowHeight={size.row} className={styles.gallery_container} >
      {images.map((item) => (
        <ImageListItem  key={item.img}>
          <img
            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            alt={item.design}
            design = {item.design}
            loading="lazy"
            className={cssc}
            onClick={func}
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}
